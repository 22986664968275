import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Apps from '../views/Admin/Apps/Apps.vue'
import AppManage from '../views/Admin/Apps/AppManage.vue'
import Suppliers from '../views/Manage/Suppliers/Suppliers.vue'
import SuppliersManage from '../views/Manage/Suppliers/SuppliersManage.vue'
import Categories from '../views/Manage/Categories/Categories.vue'
import CategoriesManage from '../views/Manage/Categories/CategoriesManage.vue'
import Products from '../views/Manage/Products/Products/Products.vue'
import ProductsManage from '../views/Manage/Products/Products/ProductsManage.vue'
import ExtrasView from '../views/Manage/Products/Extras/ExtrasView.vue'
import ExtraCategoriesManage from '../views/Manage/Products/Extras/ExtraCategories/ExtraCategoriesManage'
import ExtraManage from '../views/Manage/Products/Extras/Extras/ExtraManage'
import Inventory from '../views/Manage/Inventory.vue';

import Audits from '../views/Reports/Audit.vue';
import Refunds from '../views/Reports/Refunds.vue';
import Orders from '../views/Reports/Orders.vue';
import OnlineOrders from '../views/Reports/OnlineOrders.vue';
import LowStock from '../views/Reports/LowStock.vue';
import Takings from '../views/Reports/Takings.vue';
import WriteOff from '../views/Reports/WriteOff.vue';
import BestSellers from '../views/Reports/BestSellers.vue';
import Timesheets from '../views/Reports/Timesheets.vue';
import CurrentStock from '../views/Reports/CurrentStock.vue';
import Ordering from '../views/Reports/Ordering.vue';

import Promotions from '../views/Promotions/Promotions.vue';
import PromotionsManage from '../views/Promotions/PromotionsManage.vue';

import Settings from '../views/Manage/Settings.vue';
import PasswordReset from '../views/PasswordReset.vue';

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Home', component: Takings },

  { path: '/login', name: 'Login', component: Login },

  // { path: '/apps', name: 'Apps', meta: { requiresAdmin: true }, component: Apps, children:[
  //   { path: '/manage', name: 'Manage', component: AppManage }
  // ]},

  { path: '/apps', name: 'Apps', meta: { requiresAdmin: true }, component: Apps},
  { path: '/apps/manage/', name: 'Add App', meta: { requiresAdmin: true }, component: AppManage},
  { path: '/apps/manage/:id', name: 'Edit App', meta: { requiresAdmin: true }, component: AppManage},

  { path: '/suppliers', name: 'Suppliers', component: Suppliers },
  { path: '/suppliers/manage/', name: 'Add Supplier', component: SuppliersManage },
  { path: '/suppliers/manage/:id', name: 'Edit Supplier', component: SuppliersManage },

  { path: '/categories', name: 'Categories', component: Categories },
  { path: '/categories/manage/', name: 'Add Category', component: CategoriesManage },
  { path: '/categories/manage/:id', name: 'Edit Category', component: CategoriesManage },

  { path: '/products/list', name: 'Products', component: Products },
  { path: '/products/manage/', name: 'Add Product', component: ProductsManage },
  { path: '/products/manage/:id', name: 'Edit Product', component: ProductsManage },

  { path: '/products/extras', name: 'Extras', component: ExtrasView },
  { path: '/products/extras/categories/manage', name: 'Add Extra Category', component: ExtraCategoriesManage },
  { path: '/products/extras/categories/manage/:id', name: 'Edit Extra Category', component: ExtraCategoriesManage },
  { path: '/products/extras/manage', name: 'Add Extra', component: ExtraManage },
  { path: '/products/extras/manage/:id', name: 'Edit Extra', component: ExtraManage },
  // { path: '/products/extras/manage/', name: 'Add Extra', component: ProductsManage },
  // { path: '/products/extras/manage/:id', name: 'Edit Extra', component: ProductsManage },
  
  { path: '/inventory', name: 'Inventory', component: Inventory },

  { path: '/reports/refunds', name: 'Refunds', component: Refunds },
  { path: '/reports/orders', name: 'Orders', component: Orders },
  { path: '/reports/onlineorders', name: 'Online Orders', component: OnlineOrders },
  { path: '/reports/lowstock', name: 'Low Stock', component: LowStock },
  { path: '/reports/takings', name: 'Takings', component: Takings },
  { path: '/reports/writeoff', name: 'Write Off', component: WriteOff },
  { path: '/reports/bestsellers', name: 'Best Sellers', component: BestSellers },
  { path: '/reports/timesheets', name: 'Timesheets', component: Timesheets },
  { path: '/reports/currentstock', name: 'CurrentStock', component: CurrentStock },
  { path: '/reports/ordering', name: 'Ordering', component: Ordering },
  { path: '/reports/audit', name: 'Audits', component: Audits },

  { path: '/promotions', name: 'Promotions', component: Promotions },
  { path: '/promotions/manage', name: 'Promotions Range Add', component: PromotionsManage },
  { path: '/promotions/manage/:id', name: 'Promotions Range Edit', component: PromotionsManage },

  { path: '/settings', name: 'Settings', component: Settings },
  { path: '/password-reset', name: 'Password Reset', component: PasswordReset },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  var user = JSON.parse(localStorage.getItem('user') ?? '{}');
  const loggedIn = localStorage.getItem('user');
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);

  if (authRequired && !loggedIn) {
    return next('/login');
  }

  if (to.matched.some(record => record.meta.requiresAdmin)) {
    if (user.role != 'Admin') {
      return next('/login');
    }
  } 
    
  next();
  
})


export default router
