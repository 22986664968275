<template>
  <div>
    <v-dialog v-model="showUpload" width="500">
      <template>
        <Upload url="/product/import" :ignoreStock='ignoreStock' @close="showUpload = false;" @success="showUpload = false; load()"/>
      </template>
    </v-dialog>

    <v-row class="mb-1">
      <v-col>
        <v-btn block type="button" color="primary" v-on:click="exportExcel()">
          EXPORT
        </v-btn>
      </v-col>

      <v-col>
        <v-btn block type="button" color="primary" v-on:click="showUpload = true">
          IMPORT
        </v-btn>
      </v-col>

      <v-col>
        <v-checkbox style="margin: 0px;" v-model="ignoreStock" label="Ignore Stock"></v-checkbox>
      </v-col>

      <v-col>
        <router-link to="manage">
          <v-btn block type="button" color="primary">
            ADD NEW PRODUCT
          </v-btn>
        </router-link>
      </v-col>
    </v-row>

    <!-- <div class="d-flex mb-5">
      <v-btn type="button" color="primary" v-on:click="exportExcel()">
        EXPORT
      </v-btn>

      <v-btn type="button" color="primary" style="margin-left: 10px" v-on:click="showUpload = true">
        IMPORT
      </v-btn>

      <v-checkbox style="margin: 0px; margin-left: 10px;"
        v-model="ignoreStock"
        label="Ignore Stock"
      ></v-checkbox>

      <v-spacer></v-spacer>

      <router-link to="manage">
        <v-btn type="button" color="primary">
          ADD NEW PRODUCT
        </v-btn>
      </router-link>
    </div> -->

    <SearchBar ref="searchBar" label='Search for product' @search="filterTable($event)"/>

    <v-data-table 
      :headers="headers" :items="products" :search="search" 
      :loading="isLoading" :items-per-page="-1"
      item-key="id" class="elevation-1 mt-5">

      <template v-slot:[`item.image`]="{ item }">
        <img v-if="item.imageUrlThumbnail != null" :src="imageUrl + item.imageUrlThumbnail" />
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn type="button" color="primary" style="margin-right: 10px; font-size: 10px;" v-if="item.barcode == ''" v-on:click="generateBarcode(item)">
          GENERATE BARCODE
        </v-btn>

        <router-link :to="`manage/${item.id}`">
          <v-icon small class="ml-5">mdi-pencil</v-icon>
        </router-link>
        <v-icon class="ml-5" small @click="remove(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  import SearchBar from '@/components/SearchBar.vue';
  import Upload from '@/components/Upload.vue';

  import { productService } from '@/services';
  import { saveAs } from 'file-saver';
  import { b64toBlob } from '@/helpers'
  
  export default {
    name: 'Products',
    components: {
      SearchBar,
      Upload
    },
    data: () => ({
      imageUrl: process.env.VUE_APP_APIURL.substring(0, process.env.VUE_APP_APIURL.length - 3),
      isLoading: false,
      products: [],
      search: "",
      headers: [
        { text: '', align: 'start', sortable: false, filterable: false, value: 'image', width: 80 },
        { text: 'Name', align: 'start', sortable: true, filterable: true, value: 'name' },
        { text: 'Description', align: 'start', sortable: true, filterable: true, value: 'description' },
        { text: 'Buying Price', align: 'start', sortable: true, filterable: true, value: 'buyingPriceExVAT' },
        { text: 'Selling Price', align: 'start', sortable: true, filterable: true, value: 'sellingPrice' },
        { text: 'Margin', align: 'start', sortable: true, filterable: true, value: 'margin' },
        // { text: 'Popup Notes', align: 'start', sortable: true, filterable: true, value: 'popupNotes' },
        { text: 'Tax %', align: 'start', sortable: true, filterable: true, value: 'taxPercentage' },
        { text: 'Category', align: 'start', sortable: true, filterable: true, value: 'category.name' },
        { text: 'Supplier', align: 'start', sortable: true, filterable: true, value: 'supplier.name' },
        // { text: 'Current Stock', align: 'start', sortable: true, filterable: true, value: 'currentStock' },
        // { text: 'Min Stock', align: 'start', sortable: true, filterable: true, value: 'minStock' },
        // { text: 'Max Stock', align: 'start', sortable: true, filterable: true, value: 'maxStock' },
        { text: 'Barcode', align: 'start', sortable: true, filterable: true, value: 'barcode' },
        { text: 'Actions' , align: 'end', value: 'actions' }
      ],

      showUpload: false,
      ignoreStock: true
    }),
    created () {
      this.load();
    },
    methods: {
      filterTable: function(search) {
        this.search = search;
        localStorage.setItem("@projects-filters", search);
      },
      load: async function() {
        this.isLoading = true;
        this.products = await productService.get();
        this.isLoading = false;

        var search = localStorage.getItem("@projects-filters", search);
        if (search != null && search != "") {
          setTimeout(() => {
            this.$refs.searchBar.setSearch(search);
            this.search = search;
          }, 100);
        }
      },
      remove: async function(product) {
        this.$confirm(
        {
          message: `Are you sure you want to delete this product?`,
          button: {
            no: 'No',
            yes: 'Yes'
          },
          callback: async confirm => {
            if (confirm) {
              this.isLoading = true;
              var response = await productService.remove(product.id);
              this.isLoading = false;
              if (response.status != 200) {
                alert("Error deleting");
                return;
              }

              this.load();
            }
          }
        })
      },
      generateBarcode: function(product) {
        this.$confirm(
        {
          message: `Are you sure you want to generate a barcode?`,
          button: {
            no: 'No',
            yes: 'Yes'
          },
          callback: async confirm => {
            if (confirm) {
              this.isLoading = true;
              var response = await productService.generateBarcode(product);
              this.isLoading = false;
              if (response.status != 200) {
                alert("Error generating barcode");
                return;
              }

              this.load();
            }
          }
        })
      },
      exportExcel: async function() {
        this.isLoading = true;
        var response = await productService.exportExcel();
        if (response.status != 200) {
          alert("Error exporting");
          return;
        }

        var base64 = await response.json();
        const blob = b64toBlob(base64, 'application/vnd.ms-excel');
        saveAs(blob, 'Products.xls');

        this.isLoading = false;
      },
    }
  }
</script>
