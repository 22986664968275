import { authHeader } from '@/helpers';

export const productService = {
  get,
  getById,
  getByBarcode,
  getByCategoryId,
  add,
  update,
  remove,
  exportExcel,

  generateBarcode
};

async function get() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${process.env.VUE_APP_APIURL}/product/get`, requestOptions)
    .then((response) => response.json())
    .then(responseJson => {
      return responseJson;
    });
}

async function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${process.env.VUE_APP_APIURL}/product/getbyid?id=${id}`, requestOptions)
    .then((response) => response.json())
    .then(responseJson => {
      return responseJson;
    });
}

async function getByBarcode(barcode) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${process.env.VUE_APP_APIURL}/product/getbybarcode?barcode=${barcode}`, requestOptions)
    .then((response) => response.json())
    .then(responseJson => {
      return responseJson;
    });
}

async function getByCategoryId(categoryId, recursive) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${process.env.VUE_APP_APIURL}/product/getByCategoryId?id=${categoryId}&recursive=${recursive}`, requestOptions)
    .then((response) => response.json())
    .then(responseJson => {
      return responseJson;
    });
}

async function generateBarcode(product) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(product)
  };

  return fetch(`${process.env.VUE_APP_APIURL}/product/generatebarcode`, requestOptions)
}

async function add(category) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(category)
  };

  return fetch(`${process.env.VUE_APP_APIURL}/product/post`, requestOptions)
}

async function update(category) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(category)
  };

  return fetch(`${process.env.VUE_APP_APIURL}/product/put`, requestOptions)
}

async function remove(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(`${process.env.VUE_APP_APIURL}/product/delete?id=${id}`, requestOptions)
}

async function exportExcel() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${process.env.VUE_APP_APIURL}/product/export`, requestOptions)
}

// async function importExcel(base64) {
//   var dto = {
//     base64: base64
//   }

//   const requestOptions = {
//     method: 'POST',
//     headers: authHeader(),
//     body: JSON.stringify(dto)
//   };

//   return fetch(`${process.env.VUE_APP_APIURL}/product/import`, requestOptions)
// }