import { authHeader } from '@/helpers';

export const reportService = {
  getOrderingReport,
  getOrderReport,
  getOnlineOrderReport,
  getAuditReport,
  getRefundReport,
  getLowStockReport,
  getTakingsReport,
  exportTakingsReport,
  getWriteOffReport,
  getBestSellersReport,
  getWorstSellersReport,
  getTimesheets,
  getCurrentStockReport,
  exportCurrentStockReport,
  exportOrderingStockReport,
  exportWriteOffReport,

  getStores,
  deleteOrder,
  deleteOnlineOrder,
  deleteAudit,
};

async function exportOrderingStockReport() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${process.env.VUE_APP_APIURL}/report/exportorderingstockreport`, requestOptions)
}

async function exportCurrentStockReport() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${process.env.VUE_APP_APIURL}/report/exportcurrentstockreport`, requestOptions)
}

async function getCurrentStockReport() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${process.env.VUE_APP_APIURL}/report/getcurrentstockreport`, requestOptions)
    .then((response) => response.json())
    .then(responseJson => {
      return responseJson;
    });
}

async function getTimesheets(startDate) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  var params = "";
  params += `weekBeginning=${startDate}`;
  return fetch(`${process.env.VUE_APP_APIURL}/timesheet/getreport?${params}`, requestOptions)
  .then((response) => response.json())
  .then(responseJson => {
    return responseJson;
  });
}

async function getWorstSellersReport(startDate, endDate, storeId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  var params = "";
  params += `startDate=${startDate}`;
  params += `&endDate=${endDate}`;
  if (storeId)
    params += `&storeId=${storeId}`;

  return fetch(`${process.env.VUE_APP_APIURL}/report/getworstsellersreport?${params}`, requestOptions)
    .then((response) => response.json())
    .then(responseJson => {
      return responseJson;
    });
}

async function getBestSellersReport(startDate, endDate, storeId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  var params = "";
  params += `startDate=${startDate}`;
  params += `&endDate=${endDate}`;
  if (storeId)
    params += `&storeId=${storeId}`;

  return fetch(`${process.env.VUE_APP_APIURL}/report/getbestsellersreport?${params}`, requestOptions)
    .then((response) => response.json())
    .then(responseJson => {
      return responseJson;
    });
}

async function getOrderReport(startDate, endDate, storeId, account = "All") {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  var params = "";
  params += `startDate=${startDate}`;
  params += `&endDate=${endDate}`;
  if (storeId)
    params += `&storeId=${storeId}`;
  params += `&account=${account}`;

  return fetch(`${process.env.VUE_APP_APIURL}/report/getorderreport?${params}`, requestOptions)
    .then((response) => response.json())
    .then(responseJson => {
      return responseJson;
    });
}

async function getOnlineOrderReport(startDate, endDate, account = "All") {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  var params = "";
  params += `startDate=${startDate}`;
  params += `&endDate=${endDate}`;
  params += `&account=${account}`;

  return fetch(`${process.env.VUE_APP_APIURL}/report/getonlineordersreport?${params}`, requestOptions)
    .then((response) => response.json())
    .then(responseJson => {
      return responseJson;
    });
}

async function getRefundReport(startDate, endDate, storeId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  var params = "";
  params += `startDate=${startDate}`;
  params += `&endDate=${endDate}`;
  if (storeId)
    params += `&storeId=${storeId}`;

  return fetch(`${process.env.VUE_APP_APIURL}/report/getrefundreport?${params}`, requestOptions)
    .then((response) => response.json())
    .then(responseJson => {
      return responseJson;
    });
}

async function getAuditReport(startDate, endDate) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  var params = "";
  params += `startDate=${startDate}`;
  params += `&endDate=${endDate}`;


  return fetch(`${process.env.VUE_APP_APIURL}/audit/get?${params}`, requestOptions)
    .then((response) => response.json())
    .then(responseJson => {
      return responseJson;
    });
}


async function getOrderingReport() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${process.env.VUE_APP_APIURL}/report/getorderingreport`, requestOptions)
    .then((response) => response.json())
    .then(responseJson => {
      return responseJson;
    });
}

async function getLowStockReport() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${process.env.VUE_APP_APIURL}/report/getlowstockreport`, requestOptions)
    .then((response) => response.json())
    .then(responseJson => {
      return responseJson;
    });
}

async function getTakingsReport(startDate, endDate, storeId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  var params = "";
  params += `startDate=${startDate}`;
  params += `&endDate=${endDate}`;
  if (storeId)
    params += `&storeId=${storeId}`;

  return fetch(`${process.env.VUE_APP_APIURL}/report/gettakings?${params}`, requestOptions)
    .then((response) => response.json())
    .then(responseJson => {
      return responseJson;
    });
}

async function exportTakingsReport(startDate, endDate, storeId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  var params = "";
  params += `startDate=${startDate}`;
  params += `&endDate=${endDate}`;
  if (storeId)
    params += `&storeId=${storeId}`;

  return fetch(`${process.env.VUE_APP_APIURL}/report/exporttakings?${params}`, requestOptions)
    .then((response) => response.json())
    .then(responseJson => {
      return responseJson;
    });
}


async function getWriteOffReport(startDate, endDate) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  var params = "";
  params += `startDate=${startDate}`;
  params += `&endDate=${endDate}`;

  return fetch(`${process.env.VUE_APP_APIURL}/report/getwriteoffreport?${params}`, requestOptions)
    .then((response) => response.json())
    .then(responseJson => {
      return responseJson;
    });
}

async function exportWriteOffReport(startDate, endDate) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  var params = "";
  params += `startDate=${startDate}`;
  params += `&endDate=${endDate}`;

  return fetch(`${process.env.VUE_APP_APIURL}/report/exportwriteoffreport?${params}`, requestOptions)
    .then((response) => response.json())
    .then(responseJson => {
      return responseJson;
    });
}

async function getStores() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${process.env.VUE_APP_APIURL}/store/get`, requestOptions)
    .then((response) => response.json())
    .then(responseJson => {
      return responseJson;
    });
}

async function deleteOrder(orderId) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(`${process.env.VUE_APP_APIURL}/order/delete?orderId=${orderId}`, requestOptions)
}

async function deleteOnlineOrder(orderId) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(`${process.env.VUE_APP_APIURL}/order/deleteonlineorder?orderId=${orderId}`, requestOptions)
}

async function deleteAudit(auditId) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(`${process.env.VUE_APP_APIURL}/audit/delete?id=${auditId}`, requestOptions)
}