<template>
  <div>
    <h2><v-icon v-on:click="goBack" color="primary" medium>mdi-arrow-left</v-icon><span>Manage Promotion</span></h2>
    <v-form class="form" ref="form" v-model="valid" lazy-validation @submit.prevent="handleSubmit">
      <v-text-field v-model="name" label="Name" :rules="[v => !!v || 'Name is required']" />

      <v-checkbox v-model="usePromotionDates" label="From Dates"></v-checkbox>

      <v-menu v-model="showStartDate" :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto" v-if="usePromotionDates">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="startDateFormatted" label="Start Date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" required :rules="[v => !!v || 'Start Date is required']"></v-text-field>
        </template>
        <v-date-picker v-model="startDate" no-title scrollable required></v-date-picker>
      </v-menu>

      <v-menu v-model="showEndDate" :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto" v-if="usePromotionDates">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="endDateFormatted" label="End Date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" :rules="[v => !!v || 'End Date is required']"></v-text-field>
        </template>
        <v-date-picker v-model="endDate" no-title scrollable></v-date-picker>
      </v-menu>

      <v-select :items="types" filled label="Type" item-text="name" item-value="idx" v-model="promotionType"></v-select>

      <div v-if="promotionType != null">
        <label style="margin: 0;" v-if="promotionType == 0">Required Quantity and Amount</label>
        <div class="d-flex align-center">
          <label class="pr-5" v-if="promotionType == 0">Buy</label>
          <v-text-field v-if="promotionType == 0" v-model="buyQuantity" style="max-width: 100px;" :rules="[
            v => !!v || 'Quantity is required', 
            v => /^[1-9][\.\d]*(,\d+)?$/.test(v) || 'Invalid number'
          ]"  />
          <label class="pl-5 pr-5" v-if="promotionType == 0">For £</label>
          <!-- <label class="pl-5 pr-5" v-if="promotionType == 1">Save</label> -->
          <v-text-field v-model="buyAmount" style="max-width: 100px;" :rules="[
            v => !!v || 'Amount is required', 
            v => /^[1-9][\.\d]*(,\d+)?$/.test(v) || 'Invalid number'
          ]"   />
          <label class="pl-2" v-if="promotionType == 1">%</label>
        </div>
      </div>

      <div>
        <label>Days</label>
        <v-checkbox v-model="days[1]" label="Monday" style="margin-top: 0px; margin-bottom: 0px;" hide-details="false"></v-checkbox>
        <v-checkbox v-model="days[2]" label="Tuesday" style="margin-top: 0px; margin-bottom: 0px;" hide-details="false"></v-checkbox>
        <v-checkbox v-model="days[3]" label="Wednesday" style="margin-top: 0px; margin-bottom: 0px;" hide-details="false"></v-checkbox>
        <v-checkbox v-model="days[4]" label="Thursday" style="margin-top: 0px; margin-bottom: 0px;" hide-details="false"></v-checkbox>
        <v-checkbox v-model="days[5]" label="Friday" style="margin-top: 0px; margin-bottom: 0px;" hide-details="false"></v-checkbox>
        <v-checkbox v-model="days[6]" label="Saturday" style="margin-top: 0px; margin-bottom: 0px;" hide-details="false"></v-checkbox>
        <v-checkbox v-model="days[0]" label="Sunday" style="margin-top: 0px; margin-bottom: 0px;" hide-details="false"></v-checkbox>
      </div>
      
      <v-autocomplete v-model="category" :items="categoriesList" item-text="name" item-value="id" return-object label="Category" dense style="margin-top: 30px;"></v-autocomplete>

      <div class="d-flex align-center">
        <v-autocomplete v-model="product" :items="productsList" item-text="name" item-value="id" return-object label="Product"></v-autocomplete>
        <v-btn type="button" color="primary" class="ml-4" v-on:click="addProduct()">
          ADD
        </v-btn> 
        <v-btn type="button" color="primary" class="ml-4" v-on:click="addAllProducts()">
          ADD ALL
        </v-btn> 
         <v-btn type="button" color="primary" class="ml-4" v-on:click="clear()">
          CLEAR
        </v-btn> 
      </div>

      <div v-if="isLoading">Loading products...</div>

      <v-simple-table class="mb-4" dense height="200px" fixed-header>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Product
              </th>
              <th class="text-left">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in products" :key="item.id">
              <td>{{ item.name }}</td>
              <td><v-icon small @click="removeProduct(item)">mdi-delete</v-icon></td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-checkbox v-model="enabled" label="Enabled" style="margin-top: 0px; margin-bottom: 0px;" hide-details="false"></v-checkbox>

      <v-btn type="submit" :disabled="!valid" color="primary" class="mr-4">
        SUBMIT
      </v-btn> 

    </v-form>
  </div>
</template>

<script>

import { categoryService, productService, promotionService } from '@/services';
import moment from 'moment'

export default {
  data () {
    return {
      showStartDate: false,
      showEndDate: false,
      isLoading: false,

      id: null,
      name: null,
      usePromotionDates: false,
      startDate: null,
      endDate: null,
      promotionType: 0,
      buyQuantity: 0,
      buyAmount: null,
      days: [true, true, true, true, true, true, true],
      enabled: true,

      category: null,
      product: null,
      products: [],
      
      categoriesList: [],
      productsList: [],

      submitted: false,

      valid: false,
      types: [{idx: 0, name: "X for £"} , { idx: 1, name: "Percentage"}]
    }
  },
  async created () {
    await this.loadCategories();

    this.type = this.types[0].idx;

    var id = this.$route.params.id;
    if (id != null) {
      var dbPromotion = await promotionService.getById(id);
      this.id = dbPromotion.id;
      this.name = dbPromotion.name;
      this.usePromotionDates = dbPromotion.usePromotionDates;
      this.startDate = dbPromotion.startDate;
      this.endDate = dbPromotion.endDate;
      this.promotionType = dbPromotion.promotionType;
      this.buyQuantity = dbPromotion.buyQuantity;
      this.buyAmount = dbPromotion.buyAmount;
      this.days = dbPromotion.days;
      this.products = dbPromotion.promotionProducts;
      this.enabled = dbPromotion.enabled;
      this.category = this.categoriesList.find(c => c.id == dbPromotion.categoryId);
    }
  },
  computed: {
    startDateFormatted () {
      if (this.startDate == null)
        return "";
      return moment(this.startDate).format("DD/MM/yyyy");
    },
    endDateFormatted () {
      if (this.endDate == null)
        return "";
      return moment(this.endDate).format("DD/MM/yyyy");
    },
  },
  methods: {
    goBack: function() {
      this.$router.go(-1);
    },
    loadCategories: async function() {
      this.categoriesList = await categoryService.get();
      this.categoriesList.unshift({
        id: null,
        name: 'Please Select'
      });
      this.category = this.categoriesList[0];
    },
    addAllProducts() {
      // this.products = [];
      // this.products = JSON.parse(JSON.stringify(this.productsList));
      var prods = JSON.parse(JSON.stringify(this.productsList));
      for (let prod of prods)
        this.products.push(prod)
    },
    clear() {
      this.products = [];
    },
    addProduct() {
      if (this.product == null)
        return;

      if (this.products.filter(p => p.name == this.product.name).length > 0)
        return;

      this.products.push(JSON.parse(JSON.stringify(this.product)));
    },
    
    removeProduct: function(product) {
      var idx = this.products.indexOf(product);
      this.products.splice(idx, 1);
    },
    async handleSubmit (e) {
      if (!this.$refs.form.validate())
        return;

      if (this.products.length == 0) {
        alert("Add at least 1 product");
        return;
      }

      this.submitted = true;

      var dto = {
        id: this.id,
        name: this.name, 
        usePromotionDates: this.usePromotionDates,
        startDate: this.startDate,
        endDate: this.endDate,
        promotionType: this.promotionType,
        buyQuantity: this.buyQuantity,
        buyAmount: this.buyAmount,
        days: this.days,
        promotionProducts: this.products.map(p => p.id),
        enabled: this.enabled,
        categoryId: this.category.id,
      };

      var response = await promotionService.update(dto);

      if (response.status != 200) {
        alert("Error saving");
        return;
      }

      this.$router.go(-1);
    },
  },
  watch: {
    category: async function(newCategory, oldCategory) {
      if (newCategory.id == null)
        return;

      this.isLoading = true;
      this.productsList = await productService.getByCategoryId(newCategory.id, true);
      console.log(this.productsList.length);
      this.isLoading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
  h2 {
    display: flex;
    align-items: center;

    font-size: 20px;
    color: #58595B;

    span {
      margin-left: 15px;
    }

    i {
      margin-right: 30px;
    }
  }
</style>

