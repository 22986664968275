<template>
  <v-row>
    <v-col>
      <h2><v-icon v-on:click="goBack" color="primary" medium>mdi-arrow-left</v-icon><span>Add Product</span></h2>
      <v-form class="form" ref="form" v-model="valid" lazy-validation @submit.prevent="handleSubmit" style="max-width:100%;">
        <v-text-field v-model="position" label="Position" />

        <v-text-field v-model="sku" label="SKU (if applicable)"/>

        <v-text-field v-model="name" label="Name" :rules="[v => !!v || 'Name is required']" />

        <v-text-field v-model="description" label="Description" />

        <v-text-field v-model="taxPercentage" label="Tax Percentage" />

        <v-checkbox v-model="weightableItem" label="Weightable Item"></v-checkbox>

        <v-row>
          <v-col>
            <v-text-field  v-model="buyingPriceNoVAT" :label="buyingPriceLabel"  
            :rules="[
                v => !!v || 'Buying Price is required', 
                v => /^\$?[\d,]+(\.\d*)?$/.test(v) || 'Invalid number'
              ]" />
          </v-col>

          <v-col>
            <v-text-field readonly label="Buying Price - incl VAT" v-model="buyingPrice" />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field label="Selling Price - incl VAT" v-model="sellingPrice"
            :rules="[
                v => !!v || 'Selling Price is required', 
                v => /^-?[\d,]+(\.\d*)?$/.test(v) || 'Invalid number'
              ]" />
          </v-col>

          <v-col>
            <v-text-field readonly v-model="sellingPriceNoVAT" :label="sellingPriceLabel" />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field readonly label="Margin - incl VAT" v-model="margin"/>
          </v-col>
          <v-col>
            <v-text-field readonly label="Margin - excl VAT" v-model="marginNoVAT"/>
          </v-col>
        </v-row>

        <v-text-field v-model="popupNotes" label="Popup Notes" />

        <v-select :items="categories" item-text="name" v-model="category" item-value="id" return-object label="Category" required :rules="[
          v => !!v && v.id != null || 'Category is required'
        ]" />

        <v-select :items="suppliers" item-text="name" v-model="supplier" item-value="id" return-object label="Supplier"/>

        <v-text-field v-model="barcode" label="Barcode" />

        <v-text-field v-model="currentStock" :label="currentStockLabel"  />

        <v-text-field v-model="minStock" :label="minStockLabel"/>

        <v-text-field v-model="maxStock" :label="maxStockLabel" />

        <v-checkbox v-model="eposOnly" label="EPOS Only"></v-checkbox>

        <v-checkbox v-model="multiStoreItem" label="Multi Store Item"></v-checkbox>

        <v-text-field v-model="boxedQty" label="Boxed Qty" />
        

        <v-file-input label="Image" truncate-length="15" show-size accept="image/*" prepend-icon="mdi-camera" v-model="image" @change="upload(image)"></v-file-input>

        <img v-if="imageBase64 != null" style='display:block; margin: 10px; max-width: 250px;' :src="imageBase64" />

        <label v-if="showSuccess" style="font-weight: bold; margin-bottom: 15px; color: green">Successfully saved</label>

        <div>
          <v-btn type="submit" :disabled="!valid" color="primary" class="mr-4">
            SUBMIT
          </v-btn> 

          <v-btn type="button" :disabled="!valid" color="primary" class="mr-4" v-on:click="saveAnother()">
            SUBMIT & ADD ANOTHER
          </v-btn> 
        </div>
      </v-form>
    </v-col>

    <v-col>
      <ProductSizes ref="sizes" :productSizes="sizes"/>
    </v-col>

    <v-col >
      <ProductExtras ref="extras" :productExtras="extras"/>
    </v-col>
  </v-row>
</template>

<script>

import { authHeader, emptyGuid } from '@/helpers';
import { productService, categoryService, supplierService } from '@/services';
import ProductSizes from './ProductSizes';
import ProductExtras from './ProductExtras';

import Compressor from 'compressorjs';

export default {
  components: {
    ProductSizes,
    ProductExtras
  },
  data () {
    return {
      id: null,
      sku: '',
      name: '',
      description: '',
      buyingPriceNoVAT: '',
      sellingPrice: '',
      popupNotes: '',
      taxPercentage: 0,
      category: null,
      barcode: '',
      currentStock: '',
      minStock: '',
      maxStock: '',
      weightableItem: false,
      position: 0,
      supplier: null,

      categories: [],
      suppliers: [],
      sizes: [],
      extras: [],
      imageBase64: null,
      image: null,
      
      eposOnly: false,
      multiStoreItem: false,
      boxedQty: 0,

      submitted: false,

      valid: false,
      showSuccess: false,
    }
  },
  async created () {
    await this.loadSuppliers();
    await this.loadCategories();

    var id = this.$route.params.id;
    if (id != null) {
      var dbProduct = await productService.getById(id);

      this.id = dbProduct.id;
      this.sku = dbProduct.sku;
      this.name = dbProduct.name;
      this.description = dbProduct.description;
      this.buyingPriceNoVAT = dbProduct.taxPercentage == 0 ? dbProduct.buyingPrice : (dbProduct.buyingPrice / (100 + dbProduct.taxPercentage) * 100).toFixed(8).replace(/\.?0+$/, '');
      // this.sellingPriceNoVAT = dbProduct.taxPercentage == 0 ? dbProduct.sellingPrice : dbProduct.sellingPrice - (dbProduct.sellingPrice / 100 * dbProduct.taxPercentage);
      this.taxPercentage = dbProduct.taxPercentage;
      //this.buyingPrice = dbProduct.buyingPrice;
      this.sellingPrice = dbProduct.sellingPrice.toFixed(8).replace(/\.?0+$/, '');

      //this.sellingPrice = dbProduct.sellingPrice;
      this.popupNotes = dbProduct.popupNotes;
      this.category = this.categories.find(c => c.id == dbProduct.category.id);
      this.barcode = dbProduct.barcode;
      this.currentStock = dbProduct.currentStock;
      this.minStock = dbProduct.minStock;
      this.maxStock = dbProduct.maxStock;
      this.weightableItem = dbProduct.weightableItem;
      this.position = dbProduct.position;
      if (dbProduct.supplierId != null)
        this.supplier = this.suppliers.find(s => s.id == dbProduct.supplierId);

      this.imageBase64 = dbProduct.imageBase64;

      this.sizes = dbProduct.sizes;
      this.extras = dbProduct.extras;
      this.eposOnly = dbProduct.eposOnly;
      this.multiStoreItem = dbProduct.multiStoreItem;
      this.boxedQty = dbProduct.boxedQty;
    }
  },
  methods: {
    goBack: function() {
      this.$router.go(-1);
    },
    loadSuppliers: async function() {
      this.suppliers = await supplierService.get();
      this.supplier = this.categories[0];
    },
    loadCategories: async function() {
      var allCategories = await categoryService.get();
      allCategories = allCategories.sort((a, b) => a.name.localeCompare(b.name))
      var categories = [];

      for (let category of allCategories) {
        if (category.parentCategoryId == null ) {
          categories.push({ header: category.name, divider: true });
        }
        categories.push(category);
      }

      this.categories = categories;
      this.categories.unshift({
        id: null,
        name: 'Please select'
      });
    },
    async saveAnother () {
      this.handleSubmit(false);
    },
    async handleSubmit (reset = true) {
      if (!this.$refs.form.validate())
        return;

      this.submitted = true;

      var dto = {
        id: this.id,
        sku: this.sku,
        name: this.name,
        description: this.description,

        buyingPrice: this.buyingPrice,
        sellingPrice: this.sellingPrice,
        popupNotes: this.popupNotes,
        taxPercentage: this.taxPercentage,
        categoryId: this.category.id,
        barcode: this.barcode,
        currentStock: this.currentStock,
        minStock: this.minStock,
        maxStock: this.maxStock,
        weightableItem: this.weightableItem,
        position: this.position,
        supplierId: this.supplier?.id,
        sizes: this.$refs.sizes != null ? this.$refs.sizes.getSizes() : [],
        extras: this.$refs.extras != null ? this.$refs.extras.getExtras() : [],
        eposOnly: this.eposOnly,
        imageBase64: this.imageBase64,
        multiStoreItem: this.multiStoreItem,
        boxedQty: this.boxedQty,
      }

      var response = null;
      if (dto.id != null)
        response = await productService.update(dto);
      else
        response = await productService.add(dto);

      if (response.status != 200) {
        alert("Error saving");
        return;
      }

      if (!reset) {
        this.id = null;
        this.name = null;
        this.description = null;
        this.buyingPrice = null;
        this.sellingPrice = null;
        this.barcode = null;
        this.showSuccess = true;
        return;
      }

      this.$router.go(-1);
    },
    upload: function(file) {
      if (!file) {
        return;
      }

      new Compressor(file, {
        quality: 0.6,
        maxWidth: 512,

        success: (result) => {
          let reader = new FileReader();
          reader.onload = (event) => {
            this.imageBase64 = reader.result;
          }
          reader.readAsDataURL(result);
          
        },
        error(err) {
          console.log(err.message);
        },
      });
    },
  },

  computed: {
    // buyingPriceNoVAT() {
    //   if (this.taxPercentage == 0)
    //     return this.buyingPrice;

    //   return (+this.buyingPrice - (this.buyingPrice / 100 * this.taxPercentage)).toFixed(2);
    // },

    buyingPrice() {
      if (this.taxPercentage == 0)
        return this.buyingPriceNoVAT;

      return (+this.buyingPriceNoVAT + (this.buyingPriceNoVAT / 100 * this.taxPercentage)).toFixed(8).replace(/\.?0+$/, '');
    },

    sellingPriceNoVAT() {
      if (this.taxPercentage == 0)
        return this.sellingPrice;

      return ((+this.sellingPrice / (100 + +this.taxPercentage)) * 100).toFixed(8).replace(/\.?0+$/, '');
    },

    marginNoVAT() {
      var percentage = 0;
      var profit = this.sellingPriceNoVAT - this.buyingPriceNoVAT;
      if (isNaN(profit))
        return 0;

      if (profit > 0 && this.sellingPriceNoVAT > 0)
        percentage = profit / this.sellingPriceNoVAT * 100;

      return "£" + profit.toFixed(8).replace(/\.?0+$/, '') + " (" + percentage.toFixed(0).replace(/\.?0+$/, '') + "%)";
    },

    margin() {
      var percentage = 0;
      var profit = this.sellingPrice - this.buyingPrice;
      if (isNaN(profit))
        return 0;

      if (profit > 0 && this.sellingPrice > 0)
        percentage = profit / this.sellingPrice * 100;

      return "£" + profit.toFixed(8).replace(/\.?0+$/, '') + " (" + percentage.toFixed(0) + "%)";
    },

    buyingPriceLabel() {
      if (this.weightableItem)
        return "Buying Price (per gram) - excl VAT";

      return "Buying Price - excl VAT";
    },

    sellingPriceLabel() {
      if (this.weightableItem)
        return "Selling Price (per gram) - excl VAT";

      return "Selling Price - excl VAT";
    },

    currentStockLabel() {
      if (this.weightableItem)
        return "Current Stock (grams)";

      return "Current Stock";
    },

    minStockLabel() {
      if (this.weightableItem)
        return "Min Stock (grams)";

      return "Min Stock";
    },

    maxStockLabel() {
      if (this.weightableItem)
        return "Max Stock (grams)";

      return "Max Stock";
    }
  }
}
</script>

<style lang="scss" scoped>
  h2 {
    display: flex;
    align-items: center;

    font-size: 20px;
    color: #58595B;

    span {
      margin-left: 15px;
    }

    i {
      margin-right: 30px;
    }
  }
</style>

