<template>
  <v-container style="flex: 1;">
    <v-row>
      <v-toolbar flat class="toolbar-container" height='auto'>
        <div class="toolbar-content">
          <!-- <p>{{ label }}</p> -->
          <v-text-field v-model="searchParam" class="search-field" hide-details prepend-icon="mdi-magnify" single-line autocomplete='off' :placeholder="label" @keyup.enter="search"></v-text-field>
          <v-btn class="search-btn" type="button" color="success" v-on:click="search">
            SEARCH
          </v-btn>
        </div>
      </v-toolbar>
    </v-row>   
  </v-container>
</template>

<script>
  

  export default {
    name: 'SearchBar',
    props: ['label', 'clearOnSearch'],
    data: () => ({
      searchParam: ""
    }),
    methods: {
      search: function() {
        this.$emit('search', this.searchParam);
        if (this.clearOnSearch != null && this.clearOnSearch) {
          this.searchParam = "";
        }
      },

      setSearch: function(search) {
        this.searchParam = search;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .toolbar-container {
    background: #FFF !important;
    padding: 10px;
    height: auto;
    border-radius: 8px;

    border: 1px solid lighten(#7B5CD8, 30);
  }

  .toolbar-content {
      display: flex;
      flex-direction: row;
      width: 100%;

      .search-field {
        flex: 1;
      }

      .search-btn {
        margin-left: 20px;
      }

      // p {
      //   margin: 0px 0px 10px 0px;
      //   padding: 0;

      //   font-size: 12px;
      //   font-weight: bold;
      // }
    }

 
</style>

